import React from 'react';
import { PageProps, Link } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';

const SecondPage = ({ path }: PageProps) => {
  return (
    <Layout>
      <SEO title='Page two' />
      <h1>Hi from the second page</h1>
      <p>Welcome to page 2 ({path})</p>
      <Link to='/'>Go back to the homepage</Link>
      <div
        className='uk-card uk-card-default uk-card-body'
        style={{ zIndex: 980 }}
        uk-sticky='bottom: #offset'
      >
        Stick to the top
      </div>
    </Layout>
  );
};

export default SecondPage;
